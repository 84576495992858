import gql from "graphql-tag"

const VIEWER_FRAGMENT = gql`
  fragment Viewer on User {
    id
    firstName
    lastName
    photoUrl
    phone
    email
    ethnicity
    income
    totalContributors
    totalContributionAmountReceivedCents
    friendCount
    contactsLastSyncedAt
    onboardingGeographicState {
      id
    }
    accountFlags {
      accountFlag
      value
    }
    address {
      state {
        id
        abbreviation
      }
    }
    onboardingGeographicState {
      id
      abbreviation
      deductionPolicyCopy
      marriedFilingAmountCents
      name
      singleFilingAmountCents
      taxCenterCopy
    }
    notificationPrefs {
      notificationType
      value
    }
    organization {
      id
      name
    }
  }
`

export const GET_VIEWER = gql`
  query Viewer {
    viewer {
      ...Viewer
    }
  }
  ${VIEWER_FRAGMENT}
`

export const UPDATE_VIEWER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...Viewer
      }
      errors {
        fullMessages
      }
    }
  }
  ${VIEWER_FRAGMENT}
`

export const GET_FRIENDS = gql`
  query FindFriends(
    $after: String
    $nameCont: String
    $emailCont: String
    $phoneCont: String
  ) {
    friends(
      after: $after
      nameCont: $nameCont
      emailCont: $emailCont
      phoneCont: $phoneCont
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        firstName
        lastName

        friendStatus
        photoUrl
        email
        friendCount
      }
    }
  }
`

export const GET_FRIENDS_COUNT = gql`
  query FindFriendsCount {
    friends {
      totalCount
    }
  }
`

export const GET_CONTACTS = gql`
  query GetContacts(
    $after: String
    $search: String
    $excludeExistingUsers: Boolean
  ) {
    contacts(
      after: $after
      search: $search
      excludeExistingUsers: $excludeExistingUsers
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        fullName
        emailsWithLabels {
          id
          email
          label
        }
        phonesWithLabels {
          id
          phone
          label
        }
        associatedUsers {
          id
          friendStatus
        }
      }
    }
  }
`

export const GET_FIVE_TWO_NINE_ACCOUNTS = gql`
  query FindFiveTwoNineAccounts($search: String, $friendsOnly: Boolean) {
    fiveTwoNineAccounts(search: $search, friendsOnly: $friendsOnly) {
      nodes {
        id
        beneficiaryFirstName
        beneficiaryLastName
        beneficiaryDescription
        beneficiaryPhotoUrl
        user {
          firstName
          lastName
          email
          photoUrl
        }
      }
    }
  }
`

export const GET_UPCOMING_BIRTHDAYS = gql`
  query FindUpcomingBirthdays {
    fiveTwoNineAccounts(upcomingBirthday: true) {
      nodes {
        id
        beneficiaryFirstName
        beneficiaryLastName
        beneficiaryBirthdate
        beneficiaryPhotoUrl
        user {
          firstName
          lastName
          email
          photoUrl
        }
      }
    }
  }
`

const CONTRIBUTOR_FRAGMENT = gql`
  fragment Contributor on User {
    id
    firstName
    lastName
    photoUrl
  }
`

export const GET_CONTRIBUTIONS_WITH_ACCOUNT_ID = gql`
  query GetContributionsWithAccountId(
    $userId: ID
    $role: VisibleContributionRole
    $fiveTwoNineAccountId: ID
    $after: String
  ) {
    contributions(
      userId: $userId
      role: $role
      fiveTwoNineAccountId: $fiveTwoNineAccountId
      after: $after
    ) {
      nodes {
        id
        amountCents
        visibility
        message
        createdAt
        status
        contributor {
          ...Contributor
        }
        fiveTwoNineAccount {
          id
          beneficiaryFirstName
          beneficiaryFirstName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CONTRIBUTOR_FRAGMENT}
`

export const GET_CONTRIBUTIONS = gql`
  query GetContributions(
    $userId: ID
    $role: VisibleContributionRole
    $fiveTwoNineAccountId: ID
    $after: String
  ) {
    contributions(
      userId: $userId
      role: $role
      fiveTwoNineAccountId: $fiveTwoNineAccountId
      after: $after
    ) {
      nodes {
        id
        amountCents
        visibility
        message
        createdAt
        recurring
        status
        contributor {
          ...Contributor
        }
        fiveTwoNineAccount {
          id
          beneficiaryFirstName
          beneficiaryLastName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CONTRIBUTOR_FRAGMENT}
`

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      sessionToken
      errors {
        path
        messages
      }
    }
  }
`

export const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      firstName
      lastName
      photoUrl
      email
      friendCount
      friendStatus
      totalContributors
      id
      friendRequest {
        id
      }
    }
  }
`

export const GET_CONTRIBUTIONS_COUNT = gql`
  query GetContributionsCount {
    contributions {
      totalCount
    }
  }
`

export const GET_SENT_CONTRIBUTIONS_COUNT = gql`
  query GetSentContributions($userId: ID) {
    contributions(userId: $userId, role: CONTRIBUTOR) {
      totalCount
    }
  }
`

export const GET_RECEIVED_CONTRIBUTIONS_COUNT = gql`
  query GetReceivedContributions($userId: ID) {
    contributions(userId: $userId, role: RECIPIENT) {
      totalCount
    }
  }
`

export const CREATE_CONTRIBUTION = gql`
  mutation CreateContribution($input: CreateContributionInput!) {
    createContribution(input: $input) {
      errors {
        fullMessages
        messages
        path
      }
    }
  }
`

export const GET_RECURRING_CONTRIBUTIONS = gql`
  query GetRecurringContributions {
    recurringContributions {
      nodes {
        id
        amountCents
        visibility
        message
        createdAt
        frequency
        repeatWeekday
        repeatDay
        repeatMonth
        firstContributionDate
        contributor {
          ...Contributor
        }
        fiveTwoNineAccount {
          id
          beneficiaryFirstName
          beneficiaryFirstName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CONTRIBUTOR_FRAGMENT}
`

export const CREATE_RECURRING_CONTRIBUTION = gql`
  mutation CreateRecurringContribution(
    $input: CreateRecurringContributionInput!
  ) {
    createRecurringContribution(input: $input) {
      result {
        id
        amountCents
        fiveTwoNineAccount {
          beneficiaryFirstName
          beneficiaryLastName
        }
      }
      errors {
        fullMessages
      }
    }
  }
`

export const DELETE_RECURRING_CONTRIBUTION = gql`
  mutation DeleteRecurringContribution(
    $input: DeleteRecurringContributionInput!
  ) {
    deleteRecurringContribution(input: $input) {
      errors {
        fullMessages
      }
    }
  }
`

export const FIND_USERS = gql`
  query FindUsers(
    $after: String
    $search: String
    $emailEq: String
    $phoneEq: String
  ) {
    users(
      after: $after
      search: $search
      emailEq: $emailEq
      phoneEq: $phoneEq
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        firstName
        lastName
        friendStatus
        email
        photoUrl
        id
      }
    }
  }
`

export const INITIATE_FRIEND_REQUEST = gql`
  mutation InitiateFriendRequest($input: InitiateFriendRequestInput!) {
    initiateFriendRequest(input: $input) {
      friendRequest {
        id
      }
      errors {
        fullMessages
      }
    }
  }
`

export const INITIATED_FRIEND_REQUESTS = gql`
  query InitiatedFriendRequests($after: String) {
    initiatedFriendRequests(after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        recipient {
          id
          firstName
          lastName
          friendStatus
          photoUrl
          friendCount
        }
      }
    }
  }
`

export const RECEIVED_FRIEND_REQUESTS = gql`
  query ReceivedFriendRequests {
    receivedFriendRequests {
      nodes {
        id
        initiator {
          id
          firstName
          lastName
          friendStatus
          photoUrl
          income
          friendCount
        }
      }
    }
  }
`

export const CREATE_INVITATION = gql`
  mutation CreateInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      errors {
        fullMessages
      }
    }
  }
`

export const ACCEPT_FRIEND_REQUEST = gql`
  mutation AcceptFriendrequest($input: AcceptFriendRequestInput!) {
    acceptFriendRequest(input: $input) {
      errors {
        fullMessages
      }
    }
  }
`

export const REJECT_FRIEND_REQUEST = gql`
  mutation RejectFriendRequest($input: RejectFriendRequestInput!) {
    rejectFriendRequest(input: $input) {
      errors {
        fullMessages
      }
    }
  }
`

export const GET_PRESIGNED_URL = gql`
  mutation GetPresignedUrl($input: PresignUploadInput!) {
    presignUpload(input: $input) {
      url
      headers
      signedId
    }
  }
`

export const CREATE_529 = gql`
  mutation CreateFiveTwoNine($input: CreateFiveTwoNineAccountInput!) {
    createFiveTwoNineAccount(input: $input) {
      fiveTwoNineAccount {
        id
      }
      errors {
        fullMessages
      }
    }
  }
`

export const GET_PLAN_LIST = gql`
  query GetPlanList($after: String, $programId: ID) {
    plans(after: $after, programId: $programId) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        name
        morningStarRating
        expenseRatio
        beneficiaryAgeStart
        beneficiaryAgeEnd
        fundManager
        url
      }
    }
  }
`

export const GET_PROGRAMS_LIST = gql`
  query GetProgramsList(
    $after: String
    $geographicStateId: ID
    $ownOnly: Boolean
  ) {
    programs(
      after: $after
      geographicStateId: $geographicStateId
      ownOnly: $ownOnly
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        name
        disclosureBookletUrl
        sponsorState {
          name
        }
      }
    }
  }
`

export const GET_PLAID_LINK_TOKEN = gql`
  query GetPlaidToken($androidPackageName: String) {
    plaidLinkToken(androidPackageName: $androidPackageName)
  }
`

export const GET_PLAID_IDENTITY = gql`
  query GetPlaidAddress {
    plaidIdentity {
      firstName
      lastName
      address {
        id
        city
        state {
          id
          name
        }
        streetAddress1
        streetAddress2
        zip
      }
    }
  }
`

export const ADD_BANK_ACCOUNT = gql`
  mutation AddBankAccount($input: AddBankAccountInput!) {
    addBankAccount(input: $input) {
      result {
        id
        name
        officialName
        accountType
        accountNumberMask
      }
      errors {
        fullMessages
      }
    }
  }
`

export const REMOVE_BANK_ACCOUNT = gql`
  mutation RemoveBankAccount($input: RemoveBankAccountInput!) {
    removeBankAccount(input: $input) {
      errors {
        fullMessages
      }
    }
  }
`
export const DELETE_529_ACCOUNT = gql`
  mutation Delete529Account($input: DeleteFiveTwoNineAccountInput!) {
    deleteFiveTwoNineAccount(input: $input) {
      errors {
        fullMessages
      }
    }
  }
`
export const GET_BANK_ACCOUNT = gql`
  query GetBankAccount {
    bankAccount {
      id
      name
      officialName
      accountType
      accountNumberMask
      bankName
    }
  }
`

export const UNFRIEND_USER = gql`
  mutation UnfriendUser($input: UnfriendInput!) {
    unfriend(input: $input) {
      errors {
        fullMessages
      }
    }
  }
`

export const GET_529_ACCOUNT = gql`
  query fiveTwoNineAccount($idOrSlug: String) {
    fiveTwoNineAccount(idOrSlug: $idOrSlug) {
      id
      accountNumber
      accountOwnerFirstName
      accountOwnerLastName
      beneficiaryFirstName
      beneficiaryLastName
      beneficiaryBirthdate
      totalContributors
      totalContributions
      totalContributionAmountCents
      totalRecurringContributors
      contributorPermission
      beneficiaryDescription
      beneficiaryPhotoUrl
      beneficiaryDescription
      planId
      programId
      goalAmountCents
      user {
        id
        firstName
        photoUrl
      }
    }
  }
`
export const GET_529_ACCOUNTS = gql`
  query get529Accounts($after: String, $userId: ID) {
    fiveTwoNineAccounts(after: $after, userId: $userId) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        beneficiaryFirstName
        beneficiaryLastName
        beneficiaryDescription
        beneficiaryPhotoUrl
        totalContributors
        totalContributions
        totalRecurringContributors
        contributorPermission
        totalContributionAmountCents
        goalAmountCents
        planId
        programId
      }
    }
  }
`
export const GET_PROGRAM_BY_ID = gql`
  query GetProgramById($id: ID!) {
    program(id: $id) {
      id
      name
      customerSupportEmail
      customerSupportPhone
      disclosureBookletUrl
      websiteUrl
      morningstarRating
      minimumSubsequentContributionAmountCents
      sponsorState {
        id
        name
      }
    }
  }
`
export const GET_PLAN_BY_ID = gql`
  query FindPlan($id: ID!) {
    plan(id: $id) {
      name
      beneficiaryAgeStart
      beneficiaryAgeEnd
      expenseRatio
      fundManager
      morningStarRating
      program {
        name
        id
        sponsorState {
          abbreviation
          id
          name
        }
      }

      programId
    }
  }
`

export const UPDATE_529 = gql`
  mutation UpdateFiveTwoNineAccount($input: UpdateFiveTwoNineAccountInput!) {
    updateFiveTwoNineAccount(input: $input) {
      errors {
        fullMessages
      }
      fiveTwoNineAccount {
        user {
          id
          firstName
          lastName
          email
          photoUrl
        }
        id
        accountNumber
        accountOwnerFirstName
        accountOwnerLastName
        beneficiaryFirstName
        beneficiaryLastName
        beneficiaryBirthdate
        totalContributors
        totalContributions
        totalContributionAmountCents
        totalRecurringContributors
        contributorPermission
        beneficiaryDescription
        beneficiaryPhotoUrl
        beneficiaryDescription
        planId
        programId
        goalAmountCents
      }
    }
  }
`

export const SUBMIT_PLAN_QUESTIONNAIRE = gql`
  mutation SubmitPlanQuestionnaire($input: SubmitPlanQuestionnaireInput!) {
    submitPlanQuestionnaire(input: $input) {
      planQuestionnaire {
        id
        matchedPlan {
          id
          expenseRatio
          url
          morningStarRating
          name
          program {
            websiteUrl
            name
            sponsorState {
              name
            }
          }
        }
      }
      errors {
        fullMessages
      }
    }
  }
`

export const EMAIL_PLAN_QUESTIONNAIRE = gql`
  mutation EmailPlanQuestionnaire($input: EmailPlanQuestionnaireInput!) {
    emailPlanQuestionnaire(input: $input) {
      emailAddress
      planQuestionnaire {
        id
      }
      errors {
        fullMessages
      }
    }
  }
`

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($after: String, $read: Boolean) {
    notifications(after: $after, read: $read) {
      nodes {
        id
        notificationType
        title
        body
        read
        createdAt
        targetRoute
        friendRequest {
          id
          initiator {
            ...Contributor
          }
          recipient {
            ...Contributor
          }
        }
        contribution {
          id
          amountCents
          message
          fiveTwoNineAccount {
            beneficiaryFirstName
            beneficiaryLastName
            beneficiaryPhotoUrl
          }
          contributor {
            ...Contributor
          }
        }
        recurringContribution {
          id
          amountCents
          message
          firstContributionDate
          fiveTwoNineAccount {
            beneficiaryFirstName
            beneficiaryLastName
            beneficiaryPhotoUrl
          }
          contributor {
            ...Contributor
          }
        }
        contact {
          id
          givenName
          familyName
        }
        matchedUser {
          id
          photoUrl
          firstName
          lastName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CONTRIBUTOR_FRAGMENT}
`

export const GET_NOTIFICATIONS_COUNT = gql`
  query GetNotificationsCount($read: Boolean) {
    notifications(read: $read) {
      totalCount
    }
  }
`

export const UPDATE_NOTIFICATION_PREF = gql`
  mutation UpdateNotificationPref($input: UpdateNotificationPrefInput!) {
    updateNotificationPref(input: $input) {
      errors {
        fullMessages
      }
      user {
        notificationPrefs {
          value
          notificationType
        }
      }
    }
  }
`

export const READ_NOTIFICATION = gql`
  mutation ReadNotification($input: ReadNotificationInput!) {
    readNotification(input: $input) {
      notification {
        id
      }
      errors {
        fullMessages
      }
    }
  }
`

export const READ_NOTIFICATIONS = gql`
  mutation ReadNotifications($input: ReadUnreadNotificationsInput!) {
    readUnreadNotifications(input: $input) {
      updateCount
      errors {
        fullMessages
      }
    }
  }
`

export const FEDERAL_TAX_CENTER_INFORMATION = gql`
  query FederalTaxCenter {
    federalTaxCenter {
      eligibleContributionAmountCents
      marriedFilingAmountCents
      singleFilingAmountCents
      totalMarriedFilingAmountCents
      totalSingleFilingAmountCents
    }
  }
`

export const STATE_TAX_CENTER_INFORMATION = gql`
  query StateTaxCenter {
    stateTaxCenter {
      eligibleContributionAmountCents
      totalMarriedFilingAmountCents
      totalSingleFilingAmountCents
      geographicState {
        deductionPolicyCopy
        taxCenterCopy
        stateType
      }
    }
  }
`

export const RESET_PASSWORD_AND_LOGIN = gql`
  mutation ResetPasswordAndLogin($input: ResetPasswordAndLoginInput!) {
    resetPasswordAndLogin(input: $input) {
      sessionToken
      errors {
        fullMessages
      }
    }
  }
`

export const SEND_PASSWORD_RESET_TOKEN = gql`
  mutation SendPasswordResetToken($input: SendPasswordResetTokenInput!) {
    sendPasswordResetToken(input: $input) {
      errors {
        fullMessages
      }
    }
  }
`

export const GET_CONTRIBUTION_PROCESSING_FEE = gql`
  query GetProcessingFee($contributionAmountCents: Int!, $recipientId: ID) {
    processingFee(
      contributionAmountCents: $contributionAmountCents
      recipientId: $recipientId
    ) {
      feeAmountCents
    }
  }
`

export const ARCHIVE_USER = gql`
  mutation ArchiveUser($input: ArchiveUserInput!) {
    archiveUser(input: $input) {
      errors {
        fullMessages
      }
    }
  }
`

export const GET_VIEWER_ADDRESS = gql`
  query GetUserAddress {
    viewer {
      address {
        city
        streetAddress1
        streetAddress2
        zip
        state {
          id
          name
          abbreviation
        }
      }
    }
  }
`

export const GET_VIEWER_IDENTITY = gql`
  query GetViewerIdentity {
    viewer {
      billingFirstName
      billingLastName
      address {
        city
        streetAddress1
        streetAddress2
        zip
        state {
          id
          name
        }
      }
    }
  }
`

export const SYNC_CONTACTS = gql`
  mutation SyncContacts($input: SyncContactsInput!) {
    syncContacts(input: $input) {
      viewer {
        id
        contactsLastSyncedAt
      }
    }
  }
`

export const LOGOUT = gql`
  mutation Logout($input: LogoutInput!) {
    logout(input: $input) {
      errors {
        fullMessages
      }
    }
  }
`

export const SKYFLOW_VAULT_ACCESS = gql`
  query SkyflowVaultAccess {
    skyflowVaultAccess {
      vaultId
      vaultUrl
      environment
      accessToken
    }
  }
`

export const GET_PLAID_INSTITUTION = gql`
  query GetPlaidInstitution($plaidInstitutionId: String!) {
    plaidInstitution(plaidInstitutionId: $plaidInstitutionId) {
      id
      plaidInstitutionId
      name
      routingNumbers
    }
  }
`

export const GET_STATES = gql`
  query GetStates {
    geographicStates {
      id
      name
    }
  }
`

export const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      sessionToken
      errors {
        path
        fullMessages
      }
    }
  }
`
